import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import PocketBase from "pocketbase";
import { useInterval } from "usehooks-ts";
import jwtDecode from "jwt-decode";
import ms from "ms";

const BASE_URL = "https://tasty-activity.pockethost.io";
const fiveMinutesInMs = ms("5 minutes");
const twoMinutesInMs = ms("2 minutes");

const PocketContext = createContext({});

export const PocketProvider = ({ children }) => {
  const pb = useMemo(() => new PocketBase(BASE_URL), []);

  const [token, setToken] = useState(pb.authStore.token);
  const [user, setUser] = useState(pb.authStore.model);

  useEffect(() => {
    return pb.authStore.onChange((token, model) => {
      setToken(token);
      setUser(model);
    });
  }, []);

  const register = useCallback(async (email, password) => {
    return await pb
      .collection("users")
      .create({ email, password, passwordConfirm: password });
  }, []);

  const login = useCallback(async (email, password) => {
    return await pb.collection("users").authWithPassword(email, password);
  }, []);

  const addCmd = useCallback(async (data) => {
    return await pb
      .collection("commandes")
      .create({ name: `${data.modele}-${data.couleur}-${data.nom}`, data });
  }, []);

  const listConsult = useCallback(async () => {
    return await pb.collection("psy_consulting").getList(1, 50);
  }, []);

  const findUserPage = useCallback(async (id) => {
    return await pb.collection("psy_consulting").getFirstListItem(`id="${id}"`);
  }, []);

  const validerCmd = useCallback(async (id, status) => {
    return await pb.collection("commandes").update(id, status);
    // status 0 : en attente , status 1 : en cours de validation , status 2 : validé
  }, []);

  const updateConsult = useCallback(
    async (id, data) => {
      
      return await pb
        .collection("psy_consulting")
        .update(id, data);
        
      // status 0 : pas de session de consultation , status 1 : session en cours 1 ....
    },
    []
  );

  const updateConsultApointment = useCallback(
    async (id, sessions_appointment) => {
      return await pb
        .collection("psy_consulting")
        .update(id, sessions_appointment);
      // sessions_appointment is a JSON file {session1:"",session2:""....}
    },
    []
  );

  const logout = useCallback(() => {
    pb.authStore.clear();
  }, []);

  const refreshSession = useCallback(async () => {
    if (!pb.authStore.isValid) return;
    const decoded = jwtDecode(token);
    const tokenExpiration = decoded.exp;
    const expirationWithBuffer = (decoded.exp + fiveMinutesInMs) / 1000;
    if (tokenExpiration < expirationWithBuffer) {
      await pb.collection("users").authRefresh();
    }
  }, [token]);

  useInterval(refreshSession, token ? twoMinutesInMs : null);

  return (
    <PocketContext.Provider
      value={{
        register,
        login,
        logout,
        addCmd,
        validerCmd,
        listConsult,
        updateConsult,
        findUserPage,
        updateConsultApointment,
        user,
        token,
        pb,
      }}
    >
      {children}
    </PocketContext.Provider>
  );
};

export const usePocket = () => useContext(PocketContext);
