import React, { useCallback, useEffect, useState } from "react";
import { usePocket } from "../contexts/PocketContext";
import { Link } from "react-router-dom";


const Dashboard = () => {
  document.body.dir = "ltr";
  const { listConsult } = usePocket();
 
  const [userConsult, setUserConsult] = useState();

  useEffect(() => {
    handleData();
    console.log(userConsult);
  }, []);

  const handleData = useCallback(async () => {
    setUserConsult(await listConsult());
  }, [listConsult]);

  return (
    <div className="overflow-x-auto">
      <table className="table">
        {/* head */}
        <thead>
          <tr>
            <th>Probleme</th>
            <th>Info</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {/* row 1 */}
          {userConsult?.items?.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="flex items-center space-x-3">
                  <div>
                    <div className="font-bold">{item?.problem}</div>
                  </div>
                </div>
              </td>
              <td>
                {item?.info}
                <span className="badge badge-ghost badge-sm"></span>
              </td>
              <td>{item?.status === 0 ? "Pas encore commencer" : `Séance en cours N° ${item?.status}`} { }</td>
              <th>
              <Link to={`/dashboard/${item?.id}`} state={{ data: item }} className="btn btn-ghost btn-xs" >Details</Link>
              </th>
            </tr>
          ))}
        </tbody>
        {/* foot */}
      </table>
    </div>
  );
};

export default Dashboard;
