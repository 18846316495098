import { useCallback, useEffect, useState, useMemo } from "react";
import InputControl from "../components/InputControl";
import ChatContainer from "../components/ChatContainer";
import PocketBase from "pocketbase";
import Logo from "../components/Logo";
import Flags from "../components/Flags";

export default function App({ lang = "tn" }) {

  if(lang === "tn") 
    document.body.dir = "rtl";
  else
    document.body.dir = "ltr";

  const chatLang = {
    tn: ["مرحبا! أنا حسام معالجك النفسي. كيف يمكنني مساعدتك؟ أخبرني عن مشاكلك؟ (في رسالة دردشة واحدة من فضلك)",
    "أعطني جهة الاتصال الخاصة بك حتى أتصل بك لاحقًا. الاسم الكامل ورقم الهاتف (Whatsup)",
    "شكرا سأتصل بك قريبا"],
    fr: ["Bonjour ! Je suis Houssem votre psychothérapeute. Comment puis-je vous aider ? Parlez-moi de vos problèmes ?  (dans un seul message de chat s'il vous plaît)",
    "Donnez-moi votre contact pour que je vous contacte plus tard. Votre nom complet, numéro de téléphone (Whatsup)",
    "Merci, je vous contacterai bientôt"],
    en: [
      "Hello! I'm Houssem your psychoterapeut. How can i help you ? Tell me about your problems ? ( In one chat message plz )",
      "Give me your contact so i will contact you later. Your full Name, Phone number (Whatsup)",
      "Thanks i will contact you soon",
    ],
  };
  const systemPrompt = {
    role: "assistant",
    content:
      chatLang[lang][0],
  };
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [responseAdded, setResponseAdded] = useState(false);
  const [promptAdded, setPromptAdded] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [chatHistory, setChatHistory] = useState([systemPrompt]);
  const [chatIndex, setChatIndex] = useState(0);
  const [problem, setProblem] = useState(null);
  const [info, setInfo] = useState(null);
  const BASE_URL = "https://tasty-activity.pockethost.io";
  const pb = useMemo(() => new PocketBase(BASE_URL), []);

  const handleResponse = useCallback(async () => {
    try {
      setLoadingResponse(true);

      const responseBody = {
        chatGptResponse: [
          chatLang[lang][1],
          chatLang[lang][2],
        ],
      };
      setChatIndex(chatIndex + 1);
      setResponse(responseBody.chatGptResponse[chatIndex]);

      setResponseAdded(true);
      setPromptAdded(false);
      setLoadingResponse(false);
    } catch (error) {
      setLoadingResponse(false);
    }
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory?.length && promptAdded) {
      handleResponse();
    }
  }, [chatHistory, handleResponse, promptAdded]);

  useEffect(() => {
    if (response && responseAdded) {
      const newResponse = {
        role: "assistant",
        content: response,
      };
      setChatHistory((chatHistory) => [...chatHistory, newResponse]);

      setResponseAdded(false);
    }
  }, [response, responseAdded]);

  const getResponse = async () => {
    setPromptAdded(true);
    const newPrompt = {
      role: "user",
      content: prompt,
    };
    setChatHistory((chatHistory) => [...chatHistory, newPrompt]);
    if (chatHistory.length === 1) setProblem(newPrompt.content);
    if (chatHistory.length === 3) setInfo(newPrompt.content);
    setPrompt("");
  };

  const handleConsultation = useCallback(
    async (consultation) => {
      return await pb.collection("psy_consulting").create(consultation);
    },
    [pb]
  );

  if (chatHistory.length === 5) {
    handleConsultation({ problem, info });
  }

  return (
    <main
      className="flex min-h-screen flex-col px-4 sm:px-24 gap-y-4 pb-4"
      style={{ backgroundColor: "#1d328d" }}
    >
      <Logo />
      <Flags lang={lang} />
      <div className="grow overflow-y-auto">
        <ChatContainer chatHistory={chatHistory} lang={lang} />
      </div>
      <InputControl
        setPrompt={setPrompt}
        prompt={prompt}
        getResponse={getResponse}
        loadingResponse={loadingResponse}
        enabled={chatHistory.length === 5}
        lang={lang}
      />
    </main>
  );
}
