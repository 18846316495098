import React, { useRef, useCallback } from "react";
import { ReactComponent as LogoSvg } from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { usePocket } from "../contexts/PocketContext";

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const { login } = usePocket();

  document.body.dir = "ltr";

  const handleOnSubmit = useCallback(
    async (evt) => {
      evt?.preventDefault();
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/dashboard");
    },
    [login, navigate]
  );
  return (
    <>
      <div
        className="flex w-full justify-center pt-6"
        style={{ backgroundColor: "#1d328d" }}
      >
        <div className="card w-80">
          <div className="card-body">
            <LogoSvg widh="100px" />
          </div>
        </div>
      </div>
      <div
        className="flex w-full justify-center"
        style={{ backgroundColor: "#1d328d" }}
      >
        <div className="card w-80">
          <div className="card-body">
            <form className="text-center" onSubmit={handleOnSubmit}>
              <input
                placeholder="Nom d'utilisateur"
                ref={emailRef}
                className="input input-bordered "
              />
              <input
                type="password"
                placeholder="Mot de passe"
                ref={passwordRef}
                className="input input-bordered mt-2"
              />
              <button type="submit" className="btn btn-neutral mt-2">
                Se connecter
              </button>
            </form>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
