import React from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import UpdateConsultation from "../components/UpdateConsultation";

const Details = () => {
  const params = useParams();
  document.body.dir = "ltr";

  const location = useLocation();
  const userConsult = location.state.data;
  let sessionList = [];
  const session =
    parseInt(userConsult?.status) !== "NaN"
      ? parseInt(userConsult?.status) + 1
      : "";
  const appointment = `session${session}`;
  const nextAppointment = userConsult?.sessions_appointment
    ? userConsult?.sessions_appointment[appointment]
    : "";
  const pourcent =
    (100 / userConsult?.consulting_session) * userConsult?.status;

  if (
    userConsult?.consulting_session &&
    userConsult?.consulting_session?.length > 0
  ) {
    userConsult?.consulting_session.forEach((session, index) => {
      sessionList.push(
        <div
          className="collapse collapse-arrow join-item border border-base-300"
          key={index}
        >
          <input type="radio" name="my-accordion-4" checked="checked" />
          <div className="collapse-title text-xl font-medium">
            Session {index}
          </div>
          <div className="collapse-content">
            <input
              type="datetime-local"
              placeholder="Type here"
              className="input input-bordered w-full max-w-xs"
            />
          </div>
        </div>
      );
    });
  }
  return (
    <div class="grid gap-4">
      <div className="stats stats-vertical shadow">
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="inline-block w-8 h-8 stroke-current"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Modifier consultation</div>
          <div className="stat-title">
            <UpdateConsultation data={userConsult} />
          </div>
          <div className="stat-desc text-primary"></div>
        </div>
      </div>
      <div className="stats stats-vertical shadow">
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
              ></path>
            </svg>
          </div>
          {userConsult?.consulting_session?.length === 0 && pourcent === 0 ? (
            "Nombre de consultations non encore défini"
          ) : (
            <>
              <div
                className="radial-progress text-primary"
                style={{ "--value": pourcent }}
              >
                {userConsult?.status} / {userConsult?.consulting_session}
              </div>
              <div className="stat-desc text-primary">
                {userConsult?.consulting_session - userConsult?.status}{" "}
                consultations restante
              </div>
            </>
          )}
          {userConsult?.status > 0 ? (
            <div className="stat-title">consultations</div>
          ) : (
            ""
          )}
        </div>

        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Prochaine consultation</div>
          <div className="stat-value text-primary">
            {nextAppointment && moment(nextAppointment).format("DD/MM/YYYY")}
          </div>
          <div className="stat-desc">
            {nextAppointment && moment(nextAppointment).format("à h:mm")}
          </div>
        </div>
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Reste à payer</div>
          <div className="stat-value text-primary">
            {userConsult?.price_to_pay - userConsult?.price_payed} DT
          </div>
          <div className="stat-desc">
            Vous avez payer {userConsult?.price_payed} DT
          </div>
        </div>
      </div>
      <div className="stats stats-vertical shadow">
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Evaluer nos consultations</div>
          <div className="stat-value text-primary">
            <div className="rating gap-1">
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
                checked
              />
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
              />
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
              />
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
              />
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
              />
            </div>
          </div>
          <div className="stat-title">consultations</div>
          <div className="stat-desc text-primary">
            {userConsult?.consulting_session - userConsult?.status}{" "}
            consultations restante
          </div>
        </div>
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Info</div>
          <div className="stat-value text-primary">{userConsult?.info}</div>
          <div className="stat-desc"></div>
        </div>
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Probleme</div>
          <div className="stat-value text-primary"></div>
          <div className="stat-desc stat-problem">{userConsult?.problem}</div>
        </div>
      </div>
      {userConsult?.consulting_session?.length > 0 && (
        <div className="stats stats-vertical shadow">
          <div className="stat">
            <div className="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-8 h-8 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                ></path>
              </svg>
            </div>
            <div className="stat-title">Ajouter date de consultation</div>
            <div className="stat-value text-primary">
              <div className="join join-vertical w-full">
                {sessionList}
                <button className="btn btn-neutral">Valider</button>
              </div>
            </div>
            <div className="stat-title"></div>
            <div className="stat-desc text-primary"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
