import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { usePocket } from "../contexts/PocketContext";
import moment from "moment";

const UserPage = () => {
  const params = useParams();
  document.body.dir = "ltr";

  const { findUserPage } = usePocket();
  const [userConsult, setUserConsult] = useState();

  useEffect(() => {
    handleData();
  }, []);

  const handleData = useCallback(async () => {
    setUserConsult(await findUserPage(params.consultId));
  }, [findUserPage]);

  const session =
    parseInt(userConsult?.status) !== "NaN"
      ? parseInt(userConsult?.status) + 1
      : "";
  const appointment = `session${session}`;
  const nextAppointment = userConsult?.sessions_appointment
    ? userConsult?.sessions_appointment[appointment]
    : "";
  const pourcent =
    (100 / userConsult?.consulting_session) * userConsult?.status;

  return (
    <div class="grid grid-cols-2 gap-4">
      <div className="stats stats-vertical shadow">
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
              ></path>
            </svg>
          </div>
          <div
            className="radial-progress text-primary"
            style={{ "--value": pourcent }}
          >
            {userConsult?.status} / {userConsult?.consulting_session}
          </div>
          <div className="stat-title">consultations</div>
          <div className="stat-desc text-primary">
            {userConsult?.consulting_session - userConsult?.status}{" "}
            consultations restante
          </div>
        </div>

        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Prochaine consultation</div>
          <div className="stat-value text-primary">
            {nextAppointment && moment(nextAppointment).format("DD/MM/YYYY")}
          </div>
          <div className="stat-desc">
            {nextAppointment && moment(nextAppointment).format("à h:mm")}
          </div>
        </div>

        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Reste à payer</div>
          <div className="stat-value text-primary">
            {userConsult?.price_to_pay - userConsult?.price_payed} DT
          </div>
          <div className="stat-desc">
            Vous avez payer {userConsult?.price_payed} DT
          </div>
        </div>
      </div>
      <div className="stats stats-vertical shadow">
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Evaluer nos consultations</div>
          <div className="stat-value text-primary">
            <div className="rating gap-1">
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
                checked
              />
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
              />
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
              />
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
              />
              <input
                type="radio"
                name="rating-3"
                className="mask mask-heart bg-red-400"
              />
            </div>
          </div>
          <div className="stat-title">consultations</div>
          <div className="stat-desc text-primary">
            {userConsult?.consulting_session - userConsult?.status}{" "}
            consultations restante
          </div>
        </div>
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Info</div>
          <div className="stat-value text-primary">{userConsult?.info}</div>
          <div className="stat-desc"></div>
        </div>

        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Probleme</div>
          <div className="stat-value text-primary"></div>
          <div className="stat-desc">{userConsult?.problem}</div>
        </div>
      </div>
      <div className="stats stats-vertical shadow">
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Ajouter date de consultation</div>
          <div className="stat-value text-primary">
            <div className="join join-vertical w-full">
              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" checked="checked" />
                <div className="collapse-title text-xl font-medium">
                  Session 1
                </div>
                <div className="collapse-content">
                  <input type="datetime-local" placeholder="Type here" className="input input-bordered w-full max-w-xs" />
                </div>
              </div>
              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title text-xl font-medium">
                Session 2
                </div>
                <div className="collapse-content">
                <input type="datetime-local" placeholder="Type here" className="input input-bordered w-full max-w-xs" />
                </div>
              </div>
              <div className="collapse collapse-arrow join-item border border-base-300">
                <input type="radio" name="my-accordion-4" />
                <div className="collapse-title text-xl font-medium">
                Session 3
                </div>
                <div className="collapse-content">
                <input type="datetime-local" placeholder="Type here" className="input input-bordered w-full max-w-xs" />
                </div>
              </div>
              <button className="btn btn-neutral">Valider</button>
            </div>
          </div>
          <div className="stat-title"></div>
          <div className="stat-desc text-primary"></div>
        </div>
      </div>
    </div>
  );
};
export default UserPage;
