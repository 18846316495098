import React from "react";
import { ReactComponent as LogoSvg } from "../assets/logo.svg";

const Logo = () => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 flex justify-center items-center">
        <LogoSvg />
      </div>
    </div>
  );
};

export default Logo;
