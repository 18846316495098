import React, { useState, useCallback } from "react";

import { usePocket } from "../contexts/PocketContext";

const UpdateConsultation = ({ data }) => {
  const { updateConsult } = usePocket();
  const [formData, setFormData] = useState({
    status: data.status,
    consulting_session: data.consulting_session,
    price_to_pay: data.price_to_pay,
    price_payed: data.price_payed,
  });

  const handleUpdate = useCallback(
    async (formData) => {
        console.log(data.id, formData)
      await updateConsult(data.id, formData);
    },
    [updateConsult]
  );
  // (id, status, consulting_session, price_to_pay, price_payed)

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    // Handle form submission logic here
    handleUpdate(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
          <form onSubmit={handleSubmit}>
            <label className="label cursor-pointer" htmlFor="status">
              Séance en cours
              <input
                type="text"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
            <label
              className="label cursor-pointer"
              htmlFor="consulting_session"
            >
              Nombre de Seance de consultation
              <input
                type="text"
                name="consulting_session"
                value={formData.consulting_session}
                onChange={handleChange}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
            <label className="label cursor-pointer" htmlFor="price_to_pay">
              Totale à payer
              <input
                type="text"
                name="price_to_pay"
                value={formData.price_to_pay}
                onChange={handleChange}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
            <label className="label cursor-pointer" htmlFor="price_payed">
              Déjà payé
              <input
                type="text"
                name="price_payed"
                value={formData.price_payed}
                onChange={handleChange}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
            <button type="submit" className="btn btn-neutral">
              Mettre à jour
            </button>
          </form>
  );
};

export default UpdateConsultation;
